import type { JSONSchemaType } from 'ajv';
import type { IComponentWYSIWYGField } from '~/typings/types/component.types';

export const strictWysiwygSchema: JSONSchemaType<IComponentWYSIWYGField> = {
  type: 'object',
  properties: {
    value: { type: 'string' },
  },
  required: ['value'],
  additionalProperties: false,
};

export const nullableWysiwygSchema: JSONSchemaType<IComponentWYSIWYGField> = {
  type: 'object',
  nullable: true,
  properties: {
    value: { type: 'string' },
  },
  required: ['value'],
  additionalProperties: false,
};
